<template>
  <div class="container py-12 !max-w-4xl">
    <!-- action bar -->
    <div class="flex justify-end">
      <El-Button
        class="md:self-start"
        @click="$router.push({ name: 'News.List' })"
      >
        <font-awesome-icon :icon="['fas', 'reply']" class="mr-1" />
        回新聞列表</El-Button
      >
    </div>

    <!-- bread -->
    <div class="mt-3 text-sm space-x-3">
      <span><a href="/" class="text-primary">首頁</a></span
      ><span>&gt;</span><span class="">最新消息</span>
    </div>

    <!-- title -->
    <div class="text-4xl text-primary mt-4">
      {{ row.title || '無標題' }}
    </div>
    <!-- types -->
    <div class="flex flex-wrap mt-4">
      <div
        v-for="(item, index) in row.types"
        :key="index"
        class="mx-1 rounded-full text-sm text-white bg-info px-3 py-1 my-1"
        :style="{ backgroundColor: item.color }"
      >
        {{ item.text }}
      </div>
    </div>

    <div class="content" v-html="row.text"></div>

    <div class="space-y-8">
      <!-- 相關連結 -->
      <div>
        <div>相關連結</div>
        <div v-for="(link, i) in row.links" :key="i" class="text-primary mt-2">
          <font-awesome-icon
            :icon="['fas', 'play']"
            class="mr-2 text-xs align-baseline"
          />
          <a href="#" class="underline" @click.prevent="to(link.url)">{{
            link.urlText || link.text
          }}</a>
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-12">
      <El-Button
        class="md:self-start"
        @click="$router.push({ name: 'News.List' })"
      >
        <font-awesome-icon :icon="['fas', 'reply']" class="mr-1" />
        回新聞列表</El-Button
      >
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import News from '@/api/News.js';
import { useRoute } from 'vue-router';
const route = useRoute();

const row = ref([]);
const fetcher = async () => {
  const res = await News.show({ id: route.params.id });
  if (res.code === 1) {
    row.value = res.data;
  }
};
fetcher();
const to = (url) => {
  window.location.href = url;
};
</script>

<style lang="sass" scoped>
:deep(.content)
    @apply pb-12
    h2
        @apply text-2xl text-black my-8 leading-relaxed
    p
        @apply text-gray mt-6 leading-relaxed
</style>
